import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  
  toolbar: theme.mixins.toolbar,
  content: {
  
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  
  },
  root: {
    
    flexGrow: 1,
  
  },
  banner:{

      width:'100%',
      margin: '0px',
    
  },
  dashboardImage:{

      width:'100%',
      height:'100%', 
  
  },
  cardButton:{

  	      backgroundColor:'#191919',
          textTransform: 'none',
          margin: '5px',
          textTransaform:'none!important',
          hover :{
  
  		          background: '#191919',
		 
    	     	}
  },
  bannerContainer:{

    width:'100%', 
    display:'flex',
    padding:'0',
    paddingLeft:'0',
    paddingRight:'0',
 //   justifyContent:'center',
 //   alignItems:'center'

  },
  accordion:{
  
      padding:'10px',
      marginTop:'10',
  
  },
  accordionIcon:{

      color:'blue'

  },
  footer:{
    
          backgroundColor:'white',
          textTransaform:'none !important',
          color:'black',
          display:'block',
          position: 'fixed',
          align:'center !important',
          textAlign:'center !important',
          alignContent:'center !important',
          width: '100%',
          height: '30px',
          bottom: 0,
          font:'sans-serif',
          fontWeight: '300',
    
  },
  btnTxt:{

  		color:'white'
  
  },
  backdrop: {
  
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  
  },
  spinner:{
  
    align:"center", 
    justify:"center",
    position: 'absolute',
    left: '50%',
    top: '50%', 

  }
}));