import React from 'react';
import { Link } from 'react-router-dom';




const Pagination = ({productsPerPage, totalProducts, paginate}) => {

  const pageNumbers = [];

	 

	for(let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++ ){

	   pageNumbers.push(i);
	   
    	}

	
	return (

	    <nav>
	    	<ul className= 'pagination'>
	     
	    	{pageNumbers.map(number => (
	    		<li key={number} className='page-item'>
	    			<a onClick={() => paginate(number)}  component={ Link } to="!#" className='page-link'>
	    				{number}
	    			</a>
	    		</li>
	    	))}
	    	</ul>
	    </nav>
	
     
	)
}
export default Pagination;