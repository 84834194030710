import React, {useState, createContext} from 'react';
export const CartContext = createContext();
export const CartProvider = (props)=>{

    const [ cart, setCart]=useState([]);
    const [ cartItem, setCartItem]=useState([]);
    const [ totalCartBalance, setTotalCartBalance]=useState(0.00);
    const [ loginDetails, setLoginDetails] = useState({});
    const [ toggleDialog, setToggleDIalog] = useState(false);
    const [ selectedMusicCategoryID, setSelectedMusicCategoryID]=useState(0);
    const [ selectedLocationID, setSelectedLocationID]=useState(0);   
    const [ userLastInteraction, setUserLastInteraction] = useState('');
    // http://localhost/openStageAPI/
    const [ baseUrlContext, setBaseUrl] = useState('https://www.salvtec.com/bridge00004/');
    const [ selectedCategoryID, setSelectedCategoryID] = useState('');
    const [ categoryList, setCategoryList] = useState([])
    const [ categoryItemsList, setCategoryItemsList] = useState([]);
    const [ categoryItemsDetails, setCategoryItemsDetails] = useState([]);
    const [ myCurrentBaseURL, setMyCurrentBaseURL] = useState('');
    const [ musicCategories, setMusicCategories] = useState([]);
    let [ locations, setLocations] = useState([]);
    const [play, setPlay] = useState(false);


     const hashData = function(givenData){
         
         var res = eval(process.env.REACT_APP_INTER_ALGO.split('').reverse().join('')); 

          return res;    
        
       }



      
 return (
       <CartContext.Provider value={{
 
		       cartBag: [cart, setCart],
		       toggleDialogBag:[toggleDialog, setToggleDIalog],
		       cartItemBag: [cartItem, setCartItem],
		       categoryBag:[categoryList, setCategoryList],
           musicCategoriesBag: [musicCategories, setMusicCategories],
           locationsBag :[locations, setLocations],
           categoryItemsBag:[categoryItemsList, setCategoryItemsList],
           categoryItemsDetailBag: [ categoryItemsDetails, setCategoryItemsDetails],
           selectedCategoryIDBag:[ selectedCategoryID, setSelectedCategoryID],
		       currentBaseURLsBad:[myCurrentBaseURL, setMyCurrentBaseURL],
		       playsBag: [play, setPlay],
           selectedMusicCategoryBag:[selectedMusicCategoryID, setSelectedMusicCategoryID],
		       selectedLocationBag:[selectedLocationID, setSelectedLocationID],
		       totalCartBalanceBag: [totalCartBalance, setTotalCartBalance],
		       userLastInteractionBag: [userLastInteraction, setUserLastInteraction],
		       loginDetailsBag : [loginDetails, setLoginDetails],
           baseUrlBag : [baseUrlContext, setBaseUrl],
		       baseUrlContext:baseUrlContext,
		       baseURL:baseUrlContext, 
               dataReceiver: hashData 

		       }}>
 		{props.children}
 	   </CartContext.Provider>
 	)
}