import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography } from '@material-ui/core';
import Pagination from '../../components/Pagination/Pagination';
import Cart from '../../components/Cart/Cart';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import { CartContext } from '../../Context/CartContext';
import useStyles from '../Products/styles'; 
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';



const Login = () => {

      const classes = useStyles();
      const history = useHistory();
      const { baseUrlContext, dataReceiver }= useContext(CartContext);
      const [loginDetails, setLoginDetails] = useState({});
      const baseUrl = baseUrlContext;
      const [loginError, setLoginError] = useState('');
      let LoginDetailsBag = [{}];
      const dataDec  = dataReceiver;
      const [username, setUsername] = useState('');
      const [fullName, setFullName]= useState('');
      const user = {username, fullName};
      const [open, setOpen] = React.useState(false);



 const goToHomePage = () => history.push({
         
     pathname: '/songs',
      loginState: LoginDetailsBag

});



const hitLoginApi = async()=>{ 

        setOpen(!open);

        const password = fullName;
        const min = 100;
        const max = 999;
        const rand = Math.floor(Math.random() * (max - min + 1)) + min;
        const randPassword = `${rand}${password}`;


        function d2h(d) {
            return d.toString(16);
        }

        function h2d(h) {
            return parseInt(h, 16);
        }

        function stringToHex(tmp) {
            let str = '',
                i = 0,
                tmp_len = tmp.length,
                c;

            for (; i < tmp_len; i += 1) {

                c = tmp.charCodeAt(i);
                str += d2h(c) + '';
                
            }
            return str;
        }

        const rand3hexPassword = stringToHex(randPassword);


        let data = { username: username, password: rand3hexPassword }
        data = JSON.stringify(data);
         
          const res = await axios.post(baseUrl +'bridgeLogin.php', data )
  .then(function (response) {

    response.data = dataDec(response.data)
   

     if(response.data.code === '200'){
            

             setLoginDetails(response.data);     
             LoginDetailsBag = response.data ;  

             goToHomePage();
             setOpen(false);

         }else{

          setLoginError(response.data.error);
        }
             
     });       
  }
   

const  handleSubmit = () => {

     hitLoginApi();
 
}


const handleRegister = () => history.push({
      
     pathname: '/registration'
    
});


return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>
           
            
   
        <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        <Grid item xs={false} sm={3} md={4}/>
            
                        <Grid item xs={12} sm={6} md={4}>
                            <Card >
                                
                               <CardContent>
                                    <div style={{color:"red", align:"center"}}>{loginError}</div>
                                  <CardHeader title="Login"  align="center" />
                                  
                                     <Typography variant="h4" gutterBottom>
                                      <TextField label="Email" variant="outlined"  fullWidth size="small"  placeholder=" example@gmail.com"
                                       id="username"
                                       name="username"
                                       value={username}
                                       onChange={(e)=>setUsername(e.target.value)}
                                         InputProps={{
                                         startAdornment: (
                                         <InputAdornment position="start">
                                         <EmailIcon />
                                         </InputAdornment>
                                        ),
                                       }}
                                      />
                                      </Typography>



                                <Typography variant="h4" gutterBottom>
                                      <TextField label="Password" variant="outlined"  fullWidth size="small"  
                                        type="password"
                                       id="fullName"
                                       name="fullName"
                                       value={fullName}
                                       onChange={(e)=>setFullName(e.target.value)}
                                        
                                      />
                                      </Typography>



                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                               <span className={classes.btnTxt}>Login</span>
                          </Button>

                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleRegister()}>
                                <span className={classes.btnTxt}>Register</span>
                         </Button>
     
                    <Typography variant="body1" gutterBottom>                
                                    
                         <Button component={Link} to="/PasswordReset" style={{margin: '5px',textTransaform:'none' ,color:'black'}}> Forgot Password?</Button>
            
                    </Typography>
     
                    </CardContent>
                  </Card>

                  <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                  </Backdrop>

              </Grid>
          
                 <Grid item xs={false}  sm={3} md={4}/>
                 </Grid>

              </Grid>

     </main>
            
      )
}

export default Login;