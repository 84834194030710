import React, {useContext, useState} from 'react';
import { AppBar, Toolbar, IconButton, Button, Badge, MenuItem,  Typography} from '@material-ui/core';
import { ShoppingCart, Menu, ThumbUpTwoTone as LikeIcon, FavoriteBorderTwoTone as HeartIcon } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import HomeIcon from '@material-ui/icons/Home';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useStyles from './styles';
import { CartContext} from '../../Context/CartContext';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '../../components/Drawer/Drawer';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import MoreVertIcon from '@mui/icons-material/MoreVert';



const NavBar = () => {

      const classes = useStyles();
      const {loginDetailsBag, cartBag, baseUrlContext, totalCartBalanceBag, dialogToggleBag }= useContext(CartContext);
      const [cart, setCart] = cartBag; 
//    const [ dialogToggle, setDialogToggle] = dialogToggleBag;
      const [totalCartBalance, setTotalCartBalance] = totalCartBalanceBag;
      const [loginDetails, setLoginDetails] = loginDetailsBag;
      const baseUrl = baseUrlContext;
      const history = useHistory();    
      const location = useLocation(); 
      const [open, setOpen] = useState(false);
      let isDashboardPath = false;
      
      if(location.pathname == '/dashboard'){

        isDashboardPath = 'true';

      }
      if(location.pathname == '/'){

        isDashboardPath = 'true';

      }

      let isLoggedIn = loginDetails.fullName;


  const handleDrawer = () => {

        setOpen(true);
  
     }


  function logout(){
 
   setLoginDetails({});

  }     


function goToTop(){
  
   window.scrollTo(0, 0); 

}

const goToHomePage = () => history.push({
     pathname: '/songs'
});



const handleClickOpen = () =>{
  
  
  //    setDialogToggle(true);

}



const SubmitCart = async()=>{
  
   let data = {key:loginDetails.id, cart}
    
      data = JSON.stringify(data);
         
        const res = await axios.post(baseUrl +'bridgeReceiveCart.php', data ).then(function (response) {
   

       response.data = JSON.parse(atob((response.data).slice(0,-2).split("").reverse().join("") + (response.data).slice(-2)));

           loginDetails.balance = response.data.accBalance;
           setCart([]);
           goToHomePage();

      
});
         
}


   const startVoting = () => history.push({
     
           pathname: '/songs',
           loginState: loginDetailsBag

}) 

    const openLogin = () => history.push({

          pathname:'/login'

    })


    const openRegister = () => history.push({

          pathname:'/registration'

    })


 
return (

     <>
        <AppBar position="fixed" className={classes.appBar} color="inherit"  >
            <Toolbar >
              <IconButton color="inherit" edge="start" aria-label="menu" onClick={handleDrawer}>
               <Menu />
               </IconButton>
               <Drawer setOpen={setOpen} open={open} handleDrawer={handleDrawer}  />
                  <Typography variant="h6" className={classes.title} color="inherit">
                      
                     <span>ThumbsUp II</span>
                  
                  </Typography>
               

            <div className={classes.grow}/>

               { location.pathname ==='/login' && (
                <div className={classes.button}>

                    

                    <IconButton component={ Link } to="/" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>


                        </Badge>
                    </IconButton>
                                       
                </div>
              
              )}   


                { location.pathname ==='/songs' && (
                <div className={classes.button}>

                    <IconButton component={ Link } onClick={()=>goToTop()} aria-label="search products" color="inherit">
                        
                            <SearchIcon/>
                       
                    </IconButton>

                    <IconButton component={ Link } to="/cart" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ShoppingCart/>
                        </Badge>
                    </IconButton>
                                       
                </div>
              
              )}





               { isDashboardPath === 'true' && (

                <div className={classes.button}>
                    

                       {/*
                         <IconButton onClick={()=>openLogin()}>
                             
                                <ContactSupportIcon style={{color:'black'}} variant="contained" color="secondary1" />
                             
                          </IconButton>
                        */}   

              
              { loginDetails.id > 0 ?
                          

                          {/*<Button onClick={()=>startVoting()}>
                             Start Voting  
                                <LikeIcon style={{color:'#FF5733'}} variant="contained" color="secondary1" />
                             
                          </Button>
                          */}
                                              
                        :

                       <>  

                       <Button onClick={()=>openLogin()}>
                                Login 
                                <LockOpenIcon style={{color:'#FF5733'}} variant="contained"/>
                        </Button>

                         <Button onClick={()=>openRegister()}>
                                Register 
                                <AccountCircleIcon style={{color:'#FF5733'}} variant="contained"/>
                        </Button>

                      </>  
                   }
                
               

                </div>
              

          )}                    


           { location.pathname ==='/cart' && (
                <div className={classes.button}>
                          {  loginDetails.balance >= totalCartBalance && cart.length !== 0  ? 



                            

                          <SendIcon onClick={SubmitCart}/>

                             :

                              <Button disabled className={classes.topPayButton}  type="button" variant="contained" color="primary" >
                                { loginDetails.balance}

                             </Button>

                              }


                   
                    
                </div>
              

                

                     )}

            </Toolbar>
               </AppBar>
            
        </>
    )
}

export default NavBar

