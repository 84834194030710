import React, {useState, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography } from '@material-ui/core';
import useStyles from '../Products/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext';


const Registration = () => {

   const classes = useStyles();
   const history = useHistory();
   const [regError, setRegError] = useState('');
   let LoginDetailsBag = [{}];
   const [fullName, setFullName] = useState('');
   const [cellNumber, setCellNumber]= useState('');
   const [email, setEmail]= useState('');
   const [password, setPassword]= useState('');
   const [confirmPassword, setConfirmPassword]= useState('');
   const { baseUrlContext }= useContext(CartContext);
   const baseUrl = baseUrlContext;

 const goToLoginPage = () => history.push({
     pathname: '/login',
      loginState: 'Succesful registered'
});



const HitRegistrationApi = async()=>{

   let data = {fullName, cellNumber, email, password}
    
      data = JSON.stringify(data);
         
          const res = await axios.post(baseUrl +'bridgeReceiveRegistration.php', data )
  .then(function (response) {

     response.data = JSON.parse(atob((response.data).slice(0,-2).split("").reverse().join("") + (response.data).slice(-2)));
      if(response.data.code === '200'){
             goToLoginPage();
      }else{

         setRegError(response.data.description);
      }
        
       
  });
         
}
   

const  handleSubmit = () => {

     HitRegistrationApi();
}

    return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

   
  <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        <Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>
                            <Card >
                                
                               <CardContent>
                                    <div style={{color:"red", align:"center"}}>{regError}</div>
                                  <CardHeader title="Registration"  align="center" />
                                  
                                     <Typography variant="h4" gutterBottom>
                                      <TextField label="Email" variant="outlined"  fullWidth size="small"  placeholder=" example@gmail.com"
                                       id="email"
                                       name="email"
                                       value={email}
                                       onChange={(e)=>setEmail(e.target.value)}
                                        
                                      />
                                      </Typography>


                                    <Typography variant="h4" gutterBottom>
                                      <TextField  label="Full Name" variant="outlined"  fullWidth size="small"  placeholder="John Doe"
                                       id="fullName"
                                       
                                       name="fullName"
                                       value={fullName}
                                       onChange={(e)=>setFullName(e.target.value)}
                                         
                                      />
                                      </Typography>



                                      <Typography variant="h4" gutterBottom>
                                      <TextField label="Cell Number" variant="outlined"  fullWidth size="small"  placeholder="76000000"
                                       id="cellNumber"
                                       name="cellNumber"
                                       value={cellNumber}
                                       onChange={(e)=>setCellNumber(e.target.value)}
                                        
                                      />
                                      </Typography>




                                <Typography variant="h4" gutterBottom>
                                      <TextField label="Password" variant="outlined"  fullWidth size="small"  
                                        type="password"
                                       id="password"
                                       name="password"
                                       value={password}
                                       onChange={(e)=>setPassword(e.target.value)}
                                        
                                      />
                                      </Typography>


                                    <Typography variant="h4" gutterBottom>
                                      <TextField label="Confirm Password" variant="outlined"  fullWidth size="small"  
                                        type="password"
                                       id="confirmPassword"
                                       name="confirmPassword"
                                       value={confirmPassword}
                                       onChange={(e)=>setConfirmPassword(e.target.value)}
                                        
                                      />
                                      </Typography>


                                  <Button className={classes.checkoutButton} size="large" type="button" variant="contained" fullWidth style ={{backgroundColor:'black', 
                                    color:"white",
                                    fontStyle: 'bold'
                                  }} onClick={()=>handleSubmit()}>Submit</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                    <Grid item xs={false}  sm={4}/>
                 </Grid>

              </Grid>

  </main>
            

      )
}

export default Registration;