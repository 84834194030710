import React, { useContext} from 'react';
import { IconButton, Button, Card, CardContent, Typography } from '@material-ui/core';

import useStyles from '../../Products/styles'; 
import image from '../../../assets/images.png';
import { useHistory, Link } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material';
import {CartContext} from '../../../Context/CartContext';
import SendIcon from '@mui/icons-material/Send';


const TopCharts = ({item}) => {

     
      const classes = useStyles();
      const history = useHistory();
      const { categoryItemsBag, categoryBag, selectedCategoryIDBag, baseUrlBag, categoryItemsDetailBag }= useContext(CartContext);
      const [ baseUrlContext, setBaseUrl] = baseUrlBag;
      const [ selectedCategoryID, setSelectedCategoryID] = selectedCategoryIDBag;
      const [ categoryItemsList, setCategoryItemsList] = categoryItemsBag;
      const [ categoryList, setCategoryList] = categoryBag;
      const [categoryItemsDetails, setCategoriItemsDetails] = categoryItemsDetailBag;     	

      const handleSubmit = () =>{

      			setBaseUrl(item.baseURL);
           
           setCategoriItemsDetails(item)
      			openLogin();		

      }


      const openLogin  = () => history.push({
   
   		    pathname: '/login'
		
		});
     

return (            
          <> 

	         <Card className={classes.root}>
	                   <img src={item.iconUrl} alt={item.categoryName} className={classes.dashboardImage}/>

	                   <CardContent>
		                    <div className={classes.cardContent}>
			              
			                    <Typography variant="body1" gutterBottom>
			                      <strong>  {item.categoryame} </strong>
			                    </Typography>
			                    
  			                </div>
			                <Typography variant="body2"gutterBottom align="center">
                             <strong>
		                                         
                                  {item.description}
                             </strong>
  		                </Typography>			                         
    

                {/* type="button" component={ Link } */}
                    		
			          <Button className={classes.cardButton} variant="contained" fullWidth gutterBottom style ={{margin:'5px', color:'white', textTransaform:'none'}} onClick={()=>handleSubmit()}>
                         		Vote Here <SendIcon variant="contained" align="end" />
                         </Button>      
			                                    
                    </CardContent>

              </Card>
          </>  
      )
}

export default TopCharts;
