import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField } from '@material-ui/core';
import Artist from './Artist/Artist';
import Pagination from '../../components/Pagination/Pagination';
import Cart from '../../components/Cart/Cart';
import useStyles from './styles';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext';
import { useHistory } from 'react-router-dom';



  function Artists(){
    
   const classes = useStyles();
   const history = useHistory();
   const[artists, setArtists] = useState([]);
   const[loading, setLoading] = useState(false);
   const[currentPage, setCurrentPage] = useState(1);
   const[artistsPerPage, setArtistsPerPage] = useState(100);
   const[search, setSearch] = useState('');
   const {loginDetailsBag,baseUrlContext, selectedMusicCategoryBag, dataReceiver }= useContext(CartContext); 
   const [selectedMusicCategoryID, setSelectedMusicCategoryID] = selectedMusicCategoryBag;
   const [loginDetails, setLoginDetails] = loginDetailsBag;
   const baseUrl = baseUrlContext;
   const dataDec  = dataReceiver;

 

   useEffect(()=> {

      const fetchArtists = async()=>{
          setLoading(true);
    let res = '';
      if(selectedMusicCategoryID !==0){
        res = await axios.get(baseUrl +'bridgeBringMusicCategoryHotArtists.php?categoryID='+selectedMusicCategoryID);

         }else{

            res = await axios.get(baseUrl +'bridgeBringHotArtists.php');

         }

           res.data = dataDec(res.data);
          
          setArtists(res.data);
          setLoading(false);
      }

      fetchArtists();
      
   }, [selectedMusicCategoryID])


   // GET CURRENT ARTISTS

   const indexOfLastArtist = currentPage * artistsPerPage;
   const indexOfFirstArtist = indexOfLastArtist - artistsPerPage;
   let currentArtists = artists.slice(indexOfFirstArtist, indexOfLastArtist);

   //Change Page
   const paginate = pageNumber => setCurrentPage(pageNumber);
   let filteredArtists = [];
   if(search != ''){

         filteredArtists = artists.filter(artist => {

        return artist.stageName.toLowerCase().includes(search.toLowerCase());
        
    });
   
   }

   

   if(filteredArtists.length > 1){
        
          currentArtists =   filteredArtists;
   }



    return (
        <main className = {classes.content}>
            <div className={classes.toolbar}/>
          <Grid container justify = "center" spacing={4}>
                <Grid item container justify = "center"  xs={12} sm={12} md={6} lg={6}>
                  

                        <TextField   label=" Artist Name"   variant="filled"  fullWidth color="primary"  onChange={e => setSearch(e.target.value)} size="small" /> {filteredArtists.length} Results
                  

                    </Grid>

                </Grid> 


         <Grid container justify = "center" spacing={4}>
             {currentArtists.map((artist, index)=> (
                <Grid item key={artist.artists_id} xs={12} sm={6} md={4} lg={3}>
                    <Artist index={index} artist={artist} loading={loading}/>
    
              </Grid>
          ))}
        </Grid>
        <div className={classes.toolbar}/>
        <Pagination artistsPerPage={artistsPerPage} totalProducts ={artists.length} paginate={paginate}/>
        
    </main>
    );
   
}
export default Artists;