import React, { useState, useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Products/styles';	
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';



export const PasswordReset = () => {
	
       const classes = useStyles(); 
       const history = useHistory(); 
       const {loginDetailsBag, selectedLocationBag, baseUrlContext, userLastInteractionBag, selectedMusicCategoryBag }= useContext(CartContext); 
       const baseUrl = baseUrlContext;
       const [ open, setOpen] = useState(false);
       const [ recoveryContact, setRecoveryContact]=useState([]);
       const [ recoveryDetails, setRecoveryDetails] = React.useState([]);
       let recoveryDetailsBag = {};   
       let usernameContainer = {};
       const [regError, setRegError] = useState('');
  

const goToCodeConfirmation = () => history.push({ 
      
        pathname: '/passwordresetresponse',
        passRecoveryState: recoveryDetailsBag,
        usernameState:usernameContainer

});

const submitRecoveryAccount = async()=>{
   

   setOpen(!open); 
   
   let data = {username: recoveryContact};
    
      data = JSON.stringify(data);
         
   const res = await axios.post(baseUrl+'bridgePasswordReset.php', data )
  .then(function (response) {

   response.data = JSON.parse(atob((response.data).slice(0,-2).split("").reverse().join("") + (response.data).slice(-2)));
      
      recoveryDetailsBag = response.data; 
      usernameContainer = recoveryContact;


      goToCodeConfirmation();
      
      if(response.data === ''){
              

             goToCodeConfirmation();
             setOpen(false);
    
      }else{

         setRegError(response.data.description);
         setOpen(false)
      }
        
  });
         
}
   
     
	return (

		
      <main className = {classes.content}>
            <div className={classes.toolbar}/>
                <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
               </Backdrop>
             <Grid container direction="column">

               <Grid item></Grid>

                 <Grid item container spacing={4}>
                  
                       <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
               <Card >

             
                                
                  <CardContent>
                  <Typography align="center" variant="h6">Password Recovery</Typography><br/>

                                <Typography variant="h4" gutterBottom>
                                      <TextField label="Username" variant="outlined" fullWidth size="small"
                                      placeholder="Username"  
                                       type="text"
                                       id="resetContact"
                                       name="resetContact"
                                       onChange={(e)=>setRecoveryContact(e.target.value)}
                                                                          
                                      />
                                      </Typography>


                                  
                  <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=> submitRecoveryAccount()}>
                               <span className={classes.btnTxt}>Submit</span>
                  </Button>


                           </CardContent>
                         </Card>

                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>

  </main>

	);
}

export default PasswordReset;