import React,{useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {CartContext} from '../../Context/CartContext';
import useStyles from '../Products/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const PasswordResetStatus = () => {


   const classes = useStyles();
   const history = useHistory();
   const {loginDetailsBag, selectedLocationBag }= useContext(CartContext); 
   const passwordResetStatusBag = history.location.passRecoveryStatusState;
   
   
const doneUpdating = () => history.push({
      
     pathname: '/login'

});


    return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

               <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        <Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>
                    <Card >
                                
                    <CardContent>
                          
                              <CardHeader title="Password Reset Status"  align="center" />
                                  
                                  <Typography variant="body1" gutterBottom>                           
                                      <b>Status :{passwordResetStatusBag.resetStatus}</b>
                                  </Typography>
 

                  <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>doneUpdating()}>
                               <span className={classes.btnTxt}>Done</span>
                  </Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                    <Grid item xs={false}  sm={4}/>
                 </Grid>

              </Grid>




  </main>
            

      )
}

export default PasswordResetStatus;