import React,{useState, useContext} from 'react';
import {  CardContent, Button, Typography } from '@material-ui/core';
import { AddShoppingCart, Headset as PlayIcon, Person as ArtistIcon, PausePresentation as PauseIcon, ThumbUpTwoTone as LikeIcon, FavoriteBorderTwoTone as HeartIcon, ThumbDownTwoTone as DislikeIcon } from '@material-ui/icons';

import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from '../Products/styles';
import { useHistory,Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext';
import { ArrowRightAltOutlined, ArrowLeftOutlined } from '@material-ui/icons';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import SportsIcon from '@mui/icons-material/Sports';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import CheckroomIcon from '@mui/icons-material/Checkroom';


import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import LanguageIcon from '@mui/icons-material/Language';


export const ChipCategory = ({categoryName, product}) => {
    


       const classes = useStyles();
       const { loginDetailsBag, cartBag, playsBag  }= useContext(CartContext);
       const [cart, setCart] = cartBag;
       const [play, setPlay]= playsBag;
       const [loginDetails, setLoginDetails] = loginDetailsBag;
       const [tongleCartIcon, setTongleCartIcon]= useState(false);
       let btnAddToCartHidden = 'F';




if(loginDetails.balance < cart.length){

  btnAddToCartHidden = 'T';

}

     if(!categoryName){
 
        return <h2>Loading..</h2>
 
    }

   const addToCart = ()=>{

    const cartItem = {id:product.id, name:product.stageName, title:product.title, cellNumber:product.cellNumber, price: product.price,  image:product.image, code:product.songCode }
     
     setCart(curr =>[...curr, cartItem ]);
    
    setTongleCartIcon(true);

   }

    const removeFromBasket = (id)=>{

    setCart(cart.filter(item => item.id !== id));  
       
    setTongleCartIcon(false);

   }

   const showVideo = (product)=>{

         console.log(product)
         console.log(product.id)
         setPlay(true);
   
   }

    const hideVideo = ()=>{

     console.log(product.id) 
     

        setPlay(false);

     
     
   
   }



    return (

        <>
            {/*MUSIC CATEGORY*/}
          {categoryName.id == '1' ?

        <>

                   <Typography gutterBottom>
                                 <strong>
                                       { play ?
                                          <PauseIcon onClick={hideVideo}/> 
                                          :
                                          <PlayIcon onClick={()=> showVideo(product.id)}/>
                                        }

                                      -{product.title}  ({product.songCode})
                                  </strong>
                   </Typography>
                         

                     <Typography variant="body2" align="right" className={classes.rightIconbtn}>
                       <DislikeIcon variant="contained" onClick={ ()=> removeFromBasket(product.id)}/>
                     </Typography>

                       <Typography align="left" className={classes.leftIconbtn}>

                             { !tongleCartIcon ?
                                <LikeIcon variant="contained" onClick={addToCart} align="right"/>
                                :
                                 <HeartIcon variant="contained" onClick={addToCart} align="right"/>
                             }
                         
                             {product.likes}  votes
                       </Typography>

                       <Typography align="left" className={classes.leftIconbtn}>

                             { !tongleCartIcon ?
                                
                                <>
                                </>
                                :
                                <div>
                                
                                </div>

                             }
                       </Typography>      


                    </>         
           :

           
           
       categoryName.id == '2'  ?
        
        <>       
          <Typography gutterBottom>
                                
                                 <strong>
                                       { play ?
                                          <DoneIcon onClick={hideVideo}/> 
                                          :
                                          <RecordVoiceOverIcon onClick={showVideo}/>
                                        }

                                      -{product.title}  ({product.songCode})
                                  </strong>
                   </Typography>
                         

              <Typography variant="body2" align="right">
                  <DislikeIcon variant="contained" onClick={ ()=> removeFromBasket(product.id)}/>
                        </Typography>
                       <Typography variant="body2" align="left">

                             { !tongleCartIcon ?
                                <LikeIcon variant="contained" onClick={addToCart} align="right"/>
                                :
                                 <HeartIcon variant="contained" onClick={addToCart} align="right"/>

                             }
                         
                             
                                        
                                 {product.likes}  votes
                             </Typography>

          </>                    
             :     

    categoryName.id == '3'  ?

          <>

                   <Typography gutterBottom>
                                
                                 <strong>
                                       { play ?
                                          <DoneIcon onClick={hideVideo}/> 
                                          :
                                          <CheckroomIcon onClick={showVideo}/>
                                        }

                                      -{product.title}  ({product.songCode})
                                  </strong>
                   </Typography>
                         

                     <Typography variant="body2" align="right" >
                       <DislikeIcon variant="contained" onClick={ ()=> removeFromBasket(product.id)}/>
                        </Typography>
                       <Typography variant="body2" align="left">

                             { !tongleCartIcon ?
                                <LikeIcon variant="contained" onClick={addToCart} align="right"/>
                                :
                                 <HeartIcon variant="contained" onClick={addToCart} align="right"/>
                             }
                         
                             
                                        
                                 {product.likes}  votes
                             </Typography>
          </>

:
 
 categoryName.id == '4'  ?

          <>

                      <Typography gutterBottom>
                                 <strong>
                                       { play ?
                                          <DoneIcon onClick={hideVideo}/> 
                                          :
                                          <SportsIcon onClick={showVideo}/>
                                        }

                                      -{product.title}  ({product.songCode})
                                  </strong>
                   </Typography>
                         

                     <Typography variant="body2" align="right" >
                       <DislikeIcon variant="contained" onClick={ ()=> removeFromBasket(product.id)}/>
                        </Typography>
                       <Typography variant="body2" align="left">

                             { !tongleCartIcon ?
                                <LikeIcon variant="contained"  onClick={addToCart} align="right"/>
                                :

                                <HeartIcon variant="contained" onClick={addToCart} align="right"/>

                              }
                         
                             
                                        
                                 {product.likes}  votes
                             </Typography>
          </>

         :
         <>
         </> 


    }






                      </>       
    )





}

export default ChipCategory;