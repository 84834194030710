import React, {useEffect, useState, useContext} from 'react';
import { Grid, Card, CardContent, CardHeader, Typography, Button } from '@material-ui/core';
import {ThumbUpTwoTone as LikeIcon, FavoriteBorderTwoTone as HeartIcon, ThumbDownTwoTone as DislikeIcon } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import {CartContext} from '../../Context/CartContext'; 
import banner from '../../assets/banner.png'; 
import useStyles from '../Products/styles';
import TopCharts from './TopCharts/TopCharts';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Loader from '../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';



const Dashboard = () => {




     const classes = useStyles();
     const history = useHistory(); 
     const {loginDetailsBag, selectedLocationBag, baseUrlContext, baseURL , dataReceiver, userLastInteractionBag, selectedMusicCategoryBag, categoryItemsBag, categoryBag }= useContext(CartContext); 
     const [loginDetails, setLoginDetails] = loginDetailsBag;
     const [regError, setRegError] = useState('');
     const [ categoryItemsList, setCategoryItemsList] = categoryItemsBag;
     const myLoginData = history.location.loginState;
     const [ categoryItems, setCategoryItems] = useState([]);
     const [ open, setOpen] = useState(false);


              function getWindowDimensions() {
      
       const { innerWidth: width, innerHeight: height } = window;
              return {
                width,
                height
              };
      
      }



 const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


useEffect(() => {
          
      
          const res = axios.post('https://salvtec.com/bridgeThumbsUp00001/bridgeGetThumbsUpCategories.php' ).then(function (response) {

                      setCategoryItems(response.data);
                      setCategoryItemsList(response.data)
                     
                      });

   
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    
  }, []);

  

 let screenWidth = windowDimensions.width;




  if(typeof myLoginData !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);         
                    
      }



 const handleLoadingFeature = () =>{
 

    alert('You are currently not logged in, please login first.');


    openLogin();

 }     


     const openRegister = () => history.push({

        pathname:'registration'
    })


     const openLogin = () => history.push({

        pathname:'login'
    })

 

return (
    <main className = {classes.content}>
            
                <div className={classes.toolbar}/>
                           
                      <div className={classes.bannerContainer}>

                           <img src={banner} alt="iTableCheck" height="5%" width="100%" style={{width:'100%', height:'10%'}} className ={classes.banner}/>

                               </div>   
                        
                 
              
                  
                
              
              <Grid item container spacing={3} >
                  <Grid item xs={false} sm={12} >
                 
                              
                    
                    <Typography variant="body2" color="" align="right" gutterBottom>
                     
                    </Typography>

                  </Grid>
              </Grid>
                          
   
  <Grid container direction="column">

                  <Grid item></Grid>

           <Grid item container spacing={2} >
               <Grid item xs={false} sm={4} md={4}>
               
              

           
                            
                 <Accordion className={classes.accordion}>
                              <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                                   <span >What is Thumbs Up </span>
                               </AccordionSummary>
                               <hr/>
                        <AccordionDetails>
                          <Typography variant="body1" gutterBottom> 
                                         
                                 Connect to and invite potential customers to feedback on your product by casting their votes for your brand . Get more votes and earn royalties, be at the top amongst your competitors for our brand category and win a price.
                           
                          </Typography>                 
                        </AccordionDetails>
                      </Accordion> 

                 <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span >1.New to ThumbsUp?
                      <Button size='small' component={ Link } to="/registration" style={{transition: 'all 0.5s', cursor: 'pointer',}}>
                       <span>    Register</span>

                       </Button>

                    </span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                         To register on ThumbsUp click <Button size='small' component={ Link } to="/registration"> "Register"</Button>. To be able to use ThumbsUp you have to register on <a href='https://www.epaynetsz.com/' target='_blank'> ePayNet </a> and activate online check out using the steps below, or download from <a href="https://play.google.com/store/apps/details?id=dig.salvtec.epaynet&hl=de_CH&gl=US" target="_blank"> google play store</a>.

                     
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>  



                 <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span >2.Not registered on ePayNet?</span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                    Login to ThumbsUp, on the side menu click "Recharge", then click on "Go to ePayNet". Register and login to ePayNet. On the side menu click "Online Checkout", select "Yes" to Activate, then click on "Update". Go to stage 3. NB: You can also download ePayNet on <a href="https://play.google.com/store/apps/details?id=dig.salvtec.epaynet&hl=de_CH&gl=US" target="_blank"> PlayStore</a>
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>



                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span > 3.Registered ePayNet user?</span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                               Login to ThumbsUp. On the side menu click on "Recharge" then click on "Go to ePayNet". Login to ePayNet. On the side menu click on "Online Checkout", select "Yes" to Activate and click on "Update". Go to stage 4.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>


                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span >4.Registered on ePayNet & activated online check-out?</span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                      Login to ThumbsUp. On the side menu click on "Recharge". Enter amount of money to recharge with and click on "Submit". Enter your ePayNet username and password then click on "Submit". You will receive a push from MoMo to approve payment, if you DO NOT received it, dial *007*2# to approve.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>  


            
              </Grid>


                        <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                        </Backdrop>
            
 <Grid item xs={12} sm={8} md={8} >
      <Card >       
            
            <h4 align="center">Categories</h4>

            <CardContent>                                

                            <Grid item container spacing={1}>
                       
                           
                            { categoryItems.map((item, index)=> (
                                  <Grid item xs={12} sm={6} md={4} lg={3}>   
                                      
                                      <TopCharts item={item} key={index} /> 

                                  </Grid>
                              ))} 
                       


                              </Grid>
                                  
                           </CardContent>
                         </Card>

                        </Grid>
          
                 </Grid>

              </Grid>

  </main>
            

      )
}

export default Dashboard;