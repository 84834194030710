import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, IconButton } from '@material-ui/core';
import Product from './Product/Product';
import Pagination from '../../components/Pagination/Pagination';
import Cart from '../../components/Cart/Cart';
import useStyles from './styles';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import { useHistory } from 'react-router-dom';
import DialogComponent from '../Dialog/Dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CachedIcon from '@mui/icons-material/Cached';
import CategoryIcons from '../CategoryIcons/CategoryIcons';
import Loader from '../Loader/Loader';
import Backdrop from '@material-ui/core/Backdrop';



  function Products(){
     
           const classes = useStyles();
           const history = useHistory();
           const[ products, setProducts] = useState([]); 
           const[ loading, setLoading] = useState(false);  
           const[ currentPage, setCurrentPage] = useState(1);
           const[ productsPerPage, setProductsPerPage] = useState(100);
           const[ search, setSearch] = useState('');
           const { loginDetailsBag, selectedLocationBag, baseURL, dataReceiver, userLastInteractionBag, selectedMusicCategoryBag, categoryItemsBag, categoryBag, baseUrlBag, categoryItemsDetailBag, musicCategoriesBag }= useContext(CartContext);
           const [baseUrlContext, setBaseUrl] = baseUrlBag; 
           const dataDec  = dataReceiver;
           const [ musicCategories, setMusicCategories] = musicCategoriesBag;
           const [ categoryItemsDetails, setCategoryItemsDetails] = categoryItemsDetailBag;
           const [ categoryItemsList, setCategoryItemsList] = categoryItemsBag;
           const [ categoryList, setCategoryList] = categoryBag;
           const [ selectedMusicCategoryID, setSelectedMusicCategoryID] = selectedMusicCategoryBag;
           const [ loginDetails, setLoginDetails] = loginDetailsBag;
           const [ selectedLocationID, setSelectedLocationID] = selectedLocationBag;
           const [ userLastInteraction, setUserLastInteraction] = userLastInteractionBag;
           const [ callServer, setCallServer] = useState('');
           let baseUrl = '';
           baseUrl = baseUrlContext;
           const [ dataCategories, setDataCategories] = useState([{id:1,name:'pro'}]);
           const emails = ['username@gmail.com', 'user02@gmail.com'];
           let currentState = userLastInteraction;
           const [open, setOpen] = useState(false);
           const [selectedValue, setSelectedValue] = useState(emails[1]);
  


   const handleClickOpen = () => {
          
            setOpen(true);
        
          }


      const handleClose = (value) => {
       
          setOpen(false);
          setSelectedValue(value);

        };




            if(userLastInteraction === 'musicCategory'){
             
               currentState = 'musicCategory'+ selectedMusicCategoryID;
              
              }else if(userLastInteraction === 'location'){
            
                  currentState = 'location'+  selectedLocationID;
                
            }


    if(currentState !== callServer){

       setCallServer(currentState);
    
    }
   

   useEffect(()=> {

      
      const fetchProducts = async()=>{
            
            setLoading(true);
            let res = '';

  
            

if(selectedMusicCategoryID !==0 && userLastInteraction === 'musicCategory'){
         
         res = await axios.get(baseUrlContext + 'bridgeBringMusicCategoryHotSongs.php?categoryID='+selectedMusicCategoryID);

    }else if(userLastInteraction === 'location'){

      res = await axios.get(baseUrlContext + 'bridgeBringLocationHotSongs.php?locationID='+selectedLocationID);
         
      }else{

            res = await axios.get(baseUrlContext +'bridgeBringHotSongs.php');
         
         }

           res.data = dataDec(res.data);

           setProducts(res.data);
           setLoading(false);
      }


      getItemCategories(baseUrlContext);

      fetchProducts();

      
   }, [callServer])

 


   const filterItems = () =>{
                 
      
          }


      // GET LOGIN DETAILS FROM THE PREVIOUS PAGE
      const checkObjType = history.location.loginState;

  if (typeof checkObjType  !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);
         history.push({pathname: '/songs', state: {} });
    
  }


   // GET CURRENT PRODUCTS
   const indexOfLastProduct = currentPage * productsPerPage;
   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
   let currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

   //Change Page
   const paginate = pageNumber => setCurrentPage(pageNumber);
   let filteredProducts = [];


if(search != ''){

  filteredProducts = products.filter(product => {

        return product.stageName.toLowerCase().includes(search.toLowerCase());
 
  });
   
}

   
 if(filteredProducts.length > 1){
        
          currentProducts =   filteredProducts;
   }


const handleListItemClick = async(value) => {


      setCategoryItemsDetails(value);
      let selectedBaseURL = value.baseURL;

      const res = await axios.post(selectedBaseURL+'bridgeBringHotSongs.php').then(function (response) {

      updateCategories(selectedBaseURL);
        
      response.data = dataDec(response.data);

              setProducts(response.data);

          });


    };


const getItemCategories = async(value) => {



      const res = await axios.post(value+'bridgeBringMusicCategories.php').then(function (response) {
     
        
      response.data = dataDec(response.data);

               setMusicCategories(response.data);

          });


    };    



const updateCategories = async(selectedBaseURL) =>{

    const res = await axios.post(selectedBaseURL+'bridgeBringMusicCategories.php').then(function (response) {
              
      response.data = dataDec(response.data);

              setMusicCategories(response.data)
              setCategoryList(response.data);

         });  

  }



    return (
        <main className = {classes.content}>
            <div className={classes.toolbar}/>
          <Grid container justify = "center" spacing={4}>
                
           <Grid item container justify = "center"  xs={12} sm={12} md={6} lg={6}>
  

           <TextField label="Item Name" variant="filled" fullWidth color="primary"  onChange={e => setSearch(e.target.value)} size="small"
               InputProps={{
                    endAdornment: (
                         <InputAdornment position="end">
                           <IconButton onClick={handleClickOpen} style={{textSize:'small', fontSize:'18px'}}>
                                          Change Category

                                          <CachedIcon />
                           </IconButton> 
                          </InputAdornment>
                        ),
                    }}
                  /> {filteredProducts.length} Results
                  
                  
                         
                    </Grid>

                </Grid> 


         <Grid container justify = "center" spacing={4}>
             {currentProducts.map((product, index)=> ( 
                <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                    <Product index={index} product={product} key={index} loading={loading} productCategory={categoryItemsDetails}/>
    
              </Grid>
          ))}
        </Grid>

             <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Change Category</DialogTitle>
      <List sx={{ pt: 0 }}>
        {categoryItemsList.map((email) => (
          <ListItem button onClick={() => handleListItemClick(email)} key={email.id}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                {/*<PersonIcon />*/}

                <img src={email.iconUrl} alt={email.categoryName} className={classes.dashboardImage}/>


              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email.categoryame} />
          </ListItem>
        ))}

      </List>
    </Dialog>


        <div className={classes.toolbar}/>
        <Pagination productsPerPage={productsPerPage} totalProducts ={products.length} paginate={paginate}/>
        
    </main>
    );
   
}
export default Products;