import React from 'react';
import {Products, NavBar, Pagination, Loader, Footer, Dashboard, Cart, PasswordReset, PasswordResetResponse, PasswordResetStatus, CartItem, Login, Registration, Recharge, Artists} from './components';
import {CartProvider} from './Context/CartContext';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';


const App = () => {

    return ( 
    	<Router basename="/">
	    	<CartProvider>
		        
		        <div> 
		        
		            <NavBar /> 
		            
		                <Switch> 
		        
		                    <Route exact path = "/login">
		             		   <Login />
		             		</Route> 
		        
		             		<Route exact path = "/songs">
		             		   <Products />
		             		</Route>

		             		<Route exact path = "/cart">
		             			 <Cart/>
		             		</Route>

		             		<Route exact path = "/login">
		             		   <Login />
		             		</Route>

		             		<Route exact path = "/">
		             		   <Dashboard />
		             		</Route>

		             		<Route exact path = "/registration">
		             		   <Registration />
		             		</Route>

		             		<Route exact path = "/artists">
		             		   <Artists />
		             		</Route>
			            
		             		<Route exact path = "/recharge">
		             		   <Recharge />
		             		</Route>

		             		<Route exact path = "/passwordreset">
		             		   <PasswordReset />
		             		</Route>

		             		<Route exact path = "/passwordresetresponse">
		             		   <PasswordResetResponse />
		             		</Route>

		             		<Route exact path = "/passwordresetstatus">
		             		   <PasswordResetStatus />
		             		</Route>

		             		<Route exact path="/*">
							    <Dashboard />
							</Route>
			           
		            </Switch>
		        </div>
		      {/* <Footer/> */}   
	        </CartProvider>
	    </Router>
    ) 
}
export default App