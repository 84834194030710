import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import {CartContext} from '../../Context/CartContext';
import useStyles from '../Products/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const PasswordResetResponse = () => {


   const classes = useStyles();
   const history = useHistory();
   const {loginDetailsBag, selectedLocationBag, baseUrlContext, userLastInteractionBag, selectedMusicCategoryBag }= useContext(CartContext); 
   const baseUrl = baseUrlContext; 
   const [regError, setRegError] = useState('');
   let LoginDetailsBag = [{}];
   const [password, setNewPassword]= useState('');
   const [regOTP, setRegOTP]= useState('');
   const { baseURL } = useContext(CartContext);
   const passwordResetStatusesBag = history.location.passwordResetState;
   let passwordResetStatusBag = {};
   const usernameContainer = history.location.usernameState;

 const goToUpdatePassword = async()=>{

  

   let data = {username:usernameContainer,newPassword:password,code:regOTP};
    
      data = JSON.stringify(data);
         
          const res = await axios.post(baseUrl+'bridgePasswordResetUpdate.php', data )
  .then(function (response) {


        response.data = JSON.parse(atob((response.data).slice(0,-2).split("").reverse().join("") + (response.data).slice(-2)));

    passwordResetStatusBag = response.data;
     
      if(response.data !== ''){
  
            goToPasswordResetStatus();
    
      }else{

         setRegError(response.data.description);
         goToPasswordResetStatus();
      }
        
       
  });
         
}


const goToLogin = () => history.push({
      
     pathname: '/login'

});


const goToPasswordResetStatus = () => history.push({
      
     pathname: '/passwordresetstatus',
     passRecoveryStatusState: passwordResetStatusBag

});
   

    return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

               <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        <Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>
                <Card >
                                
                    <CardContent>
                          <div style={{color:"red", align:"center"}}>{regError}</div>
                              <CardHeader title="Password Reset" align="center" />
                                  
                                                                   

                                   <Typography variant="h4" gutterBottom>
                                      <TextField label="Enter Code" variant="outlined" fullWidth size="small" placeholder="Reset Code"
                                       id="resetCode"
                                       name="resetCode"
                                       onChange={(e)=>setRegOTP(e.target.value)}
                                      />
                                  </Typography>

                                  <Typography variant="h4" gutterBottom>
                                      <TextField  label="New Passowrd" variant="outlined"  fullWidth size="small"  placeholder="Password"
                                       id="password"
                                       name="password"
                                       value={password}
                                       onChange={(e)=>setNewPassword(e.target.value)}
                                      />
                                  </Typography>
 

                  <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>goToUpdatePassword()}>
                               <span className={classes.btnTxt}>Update</span>
                  </Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                    <Grid item xs={false}  sm={4}/>
                 </Grid>

              </Grid>

  </main>
            

      )
}

export default PasswordResetResponse;